import { ERROR_ALERT } from "../actions/commonAction";
import { ATTRIBUTES_LIST_LOADER, GET_ATTRIBUTES_LIST, UPDATE_ATTRIBUTE_VALUE_SUCCESS } from "../actions/configAttributesAction";

const initialState = {
  attributes: [],  
  loading: false,
  error: null,
};

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTRIBUTES_LIST_LOADER:
      return { ...state, loading: true, error: null };
    case GET_ATTRIBUTES_LIST:
      return { ...state, attributes: action.payload, loading: false };
      case UPDATE_ATTRIBUTE_VALUE_SUCCESS:
        return {
          ...state,
          attributes: state.attributes.map((attr) =>
            attr.id === action.payload.id 
              ? { ...attr, config_value: action.payload.config_value } 
              : attr
          ),
          loading: false,
        };
    case ERROR_ALERT:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default attributesReducer;