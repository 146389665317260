import { API } from "../constants/api";
import {  axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";

export const ATTRIBUTES_LIST_LOADER = "ATTRIBUTES_LIST_LOADER";
export const GET_ATTRIBUTES_LIST = "GET_ATTRIBUTES_LIST";
export const UPDATE_ATTRIBUTE_VALUE_SUCCESS="UPDATE_ATTRIBUTE_VALUE_SUCCESS";

export const getConfigAttributesList = () => {
  return async (dispatch, getState) => {
    dispatch({ type: ATTRIBUTES_LIST_LOADER });
    const token = getState().auth.token;

    const bodyData = {
      page: 1,
      page_size: 20, 
      search: "", 
    };

    axiosPostWithToken(`${API.CONFIG_LIST}`, bodyData, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_ATTRIBUTES_LIST,
            payload: res.data.data.results, 
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const updateAttributes = (id, newValue) => {
  return async (dispatch, getState) => {
    dispatch({ type: ATTRIBUTES_LIST_LOADER });
    const token = getState().auth.token;

    const bodyData = {
      config_value: newValue
    };

    axiosPostWithToken(`${API.UPDATE_CONFIG_VALUES}/${id}`, bodyData, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          // Dispatch success action with updated data (or just the id and new value)
          dispatch({
            type: UPDATE_ATTRIBUTE_VALUE_SUCCESS,
            payload: { id, config_value: newValue },
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload: (err && err.response && err.response.data) || "Something went wrong.",
        });
      });
  };
};