import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FbMainContainer, FbPageNotFound, FbNotification } from '@flexibees/core';
import MenuItems from '../../layouts/MenuItems';
import { useSelector, useDispatch } from 'react-redux';

import * as authAction from '../../actions/authAction';
import Loader from '../../components/utilities/loader';
import BreadCrumbs from '../../components/utilities/breadCrumbs';
import flexibeesLogo from '../../assets/flexibeesLogo.png';
import {
  fetch_NotificationList,
  set_Notification_Results,
  getNotificationCount,
} from '../../actions/notificationActions';
import FunctionRoleSkillMappingPage from '../../components/configure/FunctionRoleSkillMappingPage';

const Dashboard = lazy(() => import('./dashboard'));
const AllCandidates = lazy(() => import('./allCandidates'));
const UserManagement = lazy(() => import('./userManagement'));
const Configuration = lazy(() => import('./configure'));
const Confirmation = lazy(() => import('./confirmation'));
const Availability = lazy(() => import('./availability'));
const Profile = lazy(() => import('./profileRoutes'));
const AttributesSetting =lazy(() => import('./attributesSetting'));

const AdminRoutes = (props) => {
  const { path } = useRouteMatch();
  const userInfo = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(authAction.logout());
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayCount, setDisplayCount] = React.useState(false);
  let notificationCount = useSelector((state) => {
    return state.notification.notificationCount;
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDisplayCount(true);
    getNotificationList();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const getResultsPerPage = useSelector((state) => {
    return state.notification.notificationResultsPerPage;
  });
  const getNotificationLoader = useSelector((state) => {
    return state.notification.notificationLoader;
  });

  const getNotificationList = () => {
    dispatch(fetch_NotificationList());
  };

  useEffect(() => {
    dispatch(getNotificationCount());
  }, []);

  const notificationListItems = useSelector((state) => {
    return state.notification.notificationData;
  });

  const scrollToEnd = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - scrollTop === clientHeight) {
      dispatch(
        set_Notification_Results(getResultsPerPage + 3, getNotificationList),
      );
    }
  };

  return (
    <FbMainContainer
      flexibeesLogo={flexibeesLogo}
      onLogoutButtonClick={logoutHandler}
      appBarBreadCrumbs={<BreadCrumbs />}
      sideBarChildren={<MenuItems />}
      userName={`${userInfo?.first_name} ${userInfo?.last_name}`}
      userImage={userInfo?.profile_pic}
      onViewUserInfoButtonClick={() => {
        props.history.push(`${path}/profile`);
      }}
      onNotificationButtonClick={handleClick}
      displayNotificationCount={notificationCount === 0}
      noftificationCount={notificationCount}
      id={id}
    >
      {open && (
        <FbNotification
          notificationItems={notificationListItems}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          infiniteScroll={scrollToEnd}
          loader={getNotificationLoader}
          notificationNumbers={notificationCount}
        />
      )}

      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={`${path}/dashboard`} component={Dashboard} />
          <Route path={`${path}/user`} component={UserManagement} />
          <Route path={`${path}/candidate`} component={AllCandidates} />
          <Route path={`${path}/configuration`} component={Configuration} />
          <Route path={`${path}/confirmation`} component={Confirmation} />
          <Route path={`${path}/availability`} component={Availability} />
          <Route path={`${path}/profile`} component={Profile} />
          <Route path={`${path}/function-mapping`} component={FunctionRoleSkillMappingPage} />
          <Route path={`${path}/notification`} component={Notification} />
          <Route path={`${path}/attributes`} component={AttributesSetting} />
          <Route path={`${path}/*`} component={() => <FbPageNotFound />} />
        </Switch>
      </Suspense>
    </FbMainContainer>
  );
};

export default AdminRoutes;
