import { combineReducers } from "redux";
// import authReducer from './authentication/reducers';
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import candidateListReducer from "./candidateReducer";
import configReducer from "./configReducer";
import commonReducer from "./commonReducer";
import notificationReducer from "./notificationReducer";
import availabilityReducer from "./availabilityReducer";
import dashboardReducer from "./dashboardReducer";
import viewProjectReducer from "./viewProjectReducer";
import tabReducer from "./tabReducer";
import rolesReducer from "./customRolesReducer";
import skillsReducer from "./customSkillsReducers";
import attributesReducer from "./attributeConfigReducer";

const rootReducers = combineReducers({
  users: userReducer,
  //   auth: authReducer,
  candidates: candidateListReducer,
  auth: authReducer,
  config: configReducer,
  common: commonReducer,
  notification: notificationReducer,
  availability: availabilityReducer,
  dashboard: dashboardReducer,
  viewProject: viewProjectReducer,
  tab: tabReducer,
  customRoles: rolesReducer,
  customSkills: skillsReducer,
  attributes: attributesReducer
});

export default rootReducers;
